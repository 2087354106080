<template>
  <div class="mine_common_box">
    <div
      class="mine_common"
      center_bgc
      v-if="!common_close"
    >
      <NoneData v-if="common_list.length === 0"></NoneData>
      <div
        class="book_item"
        v-for="(item, index) in common_list"
        :key="index"
        v-else
        @click="get_common(item.name, item.books_id)"
      >
        <div class="rank_one">
          <img
            v-if="item.cover_image"
            :src="item.cover_image"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/default.png"
            alt=""
          />
        </div>
        <p ellipsis>{{ item.name }}</p>
        <span class="commnd">{{ item.count || 0 }}条评论</span>
      </div>
    </div>
    <div :class="!common_close ? 'hidden_common' : 'none_common'">
      <p class="show_common_title">
        <span
          class="iconfont icon-back"
          @click="close"
        ></span>
        <span @click="to_bookinfo(book_id)">{{ book_name }}</span>
      </p>
      <el-table
        :data="book_common"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="内容"
          align="center"
        >
          <template slot-scope="scope">
            <div class="out_box">
              <div class="none_common_item">
                <span
                  class="none_common_title"
                  @click="open_comm(scope.row)"
                  words-hidden
                  one-clamp
                >{{
                  scope.row.content
                  }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="new_push"
          label="发表时间"
          align="center"
        >
          <template slot-scope="scope">
            <div class="out_box">
              <div class="none_common_item">
                <span class="none_common_time">{{
                  scope.row.create_time
                  }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="read"
          label="章节"
          align="center"
        >
          <template slot-scope="scope">
            <div class="out_box">
              <div class="none_common_item">
                <span
                  class="none_common_name"
                  @click="to_bookinfo(book_id, scope.row.id)"
                >{{ scope.row.title || '--' }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="do_it"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <div class="out_box">
              <div class="none_common_item">
                <span
                  class="none_common_delete"
                  @click="delete_common(book_id, scope.row.id)"
                >删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-if="show_comm_title"
      class="show_comm_title"
      flex
      center
      align
    >
      <div class="show_comm_content">

        <p
          flex
          between
          align
        >
          <span>
            <span
              class="show_book_name"
              @click="to_bookinfo(book_id)"
            >{{ book_name }}</span>
            <span
              class="show_title"
              @click="to_bookinfo(book_id, row.id)"
              v-if="row.title"
            >{{
              row.title
              }}</span>
          </span>
          <span
            class="iconfont icon-guanbi"
            @click="close_comm"
          ></span>
        </p>

        <div class="com_content">
          <div class="com_content_my">
            <p
              class="com_content_con"
              words-hidden
              book_room_intro
            >
              {{ replay.fir_data.content||'' }}
            </p>
            <p class="time">{{ replay.fir_data.create_time||'' }}</p>
          </div>
          <template v-for="(item,index) in replay.reply_data">
            <div
              class="com_content_author"
              v-if="replay.reply_data&&replay.reply_data.length>0"
              :key="index"
            >
              <p>{{replay.reply_data?'作者回复':''}}</p>
              <p
                class="com_content_con"
                words-hidden
                book_room_intro
              >
                {{item.content}}
              </p>
              <p class="time">{{ item.create_time }}</p>
            </div>
          </template>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {
  Comment,
  Delcomment,
  Comment_author,
  Comments,
} from "@/https/mine_center.js";
export default {
  name: "mine_common",
  components: {},
  data () {
    return {
      common_list: [],
      book_common: [],
      book_name: "",
      book_id: "",
      common_close: false,
      show_comm_title: false,
      row: {},
      replay: '',
      cur_book: {},
    };
  },
  methods: {
    // 打开弹窗并展示评论
    open_comm (row) {
      this.row = row;
      this.show_comm_title = !this.show_comm_title;
      // 从后台获取评论信息(作者回复)
      let params = {
        reply_id: row.id,
        // is_author: row.is_author
      };
      Comment_author(params).then((res) => {
        this.replay = res.data;
      });
    },
    // 关闭弹窗
    close_comm () {
      this.show_comm_title = !this.show_comm_title;
    },
    // 获取书籍列表
    get_comment_book () {
      Comment().then((res) => {
        this.common_list = res.data;
      });
    },
    // 获取评论列表
    get_common (book_name, book_id) {
      this.book_id = book_id;
      this.common_close = !this.common_close;
      this.book_name = book_name;
      let params = {
        bookid: book_id,
      };
      Comments(params).then((res) => {
        this.book_common = res.data;
      });
    },
    // 删除评论
    delete_common (book_id, del) {
      this.$confirm("确定删除该条评论吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            comments_id: del,
          };
          Delcomment(params).then((res) => {
            this.get_common(this.book_name, book_id);
            this.get_common(this.book_name, book_id);
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 书籍详情
    to_bookinfo (book_id, page_id) {
      // 放缓存
      // sessionStorage.setItem('book_id',book_id);
      // sessionStorage.setItem('section_id',page_id);
      const { href } = this.$router.resolve({
        path: "/show_book",
        // 从路由传参
        query: {
          id: book_id,
          page: page_id,
        },
      });
      window.open(href, '_blank')
    },
    // 返回
    close () {
      this.common_close = !this.common_close;
      this.get_comment_book();
      this.get_comment_book();
    },
  },
  created () {
    this.get_comment_book();
  },
  mounted () { },
  watch: {
    common_list () { },
  },
};
</script>
<style lang="scss" scoped>
@import '../../utils/tool.scss';
.mine_common_box {
  width: 100%;
  height: 100%;
  min-height: 570px;
  // box-shadow: 0 0 5px #e4e4e4;
  border-radius: 5px;
}
.mine_common {
  padding: 55px;
  // box-shadow: 0 0 5px #eeeeee;
  @include flex-row-left();
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 570px;
  flex-wrap: wrap;
}
.book_item {
  @include flex-column-center();
  margin: 10px;
  .rank_one {
    width: 120px;
    height: 169px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 0 5px #f9f2e9 inset;
    img {
      cursor: pointer;
      width: 120px;
      height: 169px;
      border-radius: 2px;
      transition: all 0.5s;
    }
    img:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  & > p:nth-of-type(1) {
    text-align: center;
    width: 120px;
    cursor: pointer;
    margin: 8px 0;
  }
  & > p:nth-of-type(1):hover {
    color: #409eff;
  }
  .commnd {
    font-size: 12px;
    color: #969696;
  }
}
.none_common {
  // position: absolute;
  width: 100%;
  height: 100%;
  color: #999;
  background-color: #fff;
  opacity: 1;
  // top: 0;
  // left: 0;
  padding: 20px;
  margin: 0 auto;
  img {
    opacity: 0.5;
  }
  .none_title {
    top: -100px;
  }
  .show_common_title {
    background-color: #fff;
    color: #333;
    line-height: 30px;
    span {
      cursor: pointer;
      margin-right: 10px;
    }
    & span:nth-of-type(1) {
      font-size: 18px;
      cursor: pointer;
    }
    & span:nth-of-type(1):hover {
      color: #409eff;
    }
    & span:nth-of-type(2):hover {
      color: #409eff;
    }
  }
  .shelf_title {
    line-height: 30px;
    padding: 10px 30px;
    & span:nth-of-type(1) {
      font-size: 16px;
    }
    & span:nth-of-type(2) {
      cursor: pointer;
    }
    & span:nth-of-type(2):hover {
      color: #409eff;
    }
  }
  .cover_img_box {
    width: 100%;
    @include flex-center();
    .cover_img {
      width: 88px;
      height: 124px;
      cursor: pointer;
    }
  }
  .out_box {
    width: 100%;
    @include flex-center();
    color: #999;
  }
  .none_common_item {
    @include flex-column-left();
    line-height: 30px;
  }

  .none_common_title {
    color: #003399;
    cursor: pointer;
  }
  .none_common_delete {
    cursor: pointer;
  }
  .none_common_name {
    cursor: pointer;
    color: #333;
  }
  .none_common_name:hover,
  .none_common_title:hover {
    color: #409eff;
  }
  .none_common_delete:hover {
    color: #fc6868;
  }
}
.hidden_common {
  display: none;
}
.show_comm_title {
  z-index: 2000;
  background-color: rgba(102, 102, 102, 0.498039215686275);
  width: 100vw;
  height: calc(100vh + 30px);
  position: absolute;
  top: 0;
  left: 0;
  .show_comm_content {
    color: #333;
    font-size: 14px;
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    width: 800px;
    height: 400px;
    > p {
      .iconfont {
        font-size: 16px;
        cursor: pointer;
      }
      .iconfont:hover {
        color: #409eff;
      }
      & span:nth-of-type(1) {
        .show_title {
          cursor: pointer;
          margin-left: 20px;
        }
        .show_title:hover {
          color: #409eff;
        }
      }
    }
    .com_content {
      .com_content_my {
        border-bottom: 1px dotted #999;
        padding: 20px 0;
        & p {
          margin-bottom: 15px;
        }
      }
      .com_content_author {
        margin-top: 30px;
        & p:nth-of-type(1) {
          font-weight: bold;
        }
        & p {
          margin-bottom: 15px;
        }
      }
      .time {
        color: #999;
        font-size: 12px;
      }
      .com_content_con {
        line-height: 30px;
      }
    }
  }
}
.nothing_pic {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.none_title {
  position: relative;
  top: -20px;
}
.show_book_name {
  cursor: pointer;
}
.show_book_name:hover {
  color: #409eff;
}
</style>
